import React, { useEffect, useState } from "react"
import { Box, Form, Heading, FormField, TextInput, Button, Text } from "grommet"
import {
  fetchApi,
  reportError,
  navigate,
  getQuery,
  getGitInfo,
  sleep,
} from "../../utils"
import { Layout, SEO } from "../../components"

import {
  toast,
  LoadingWithText,
  ErrorInfo,
  FormattedMessage,
  Spinner,
} from "../../components"
import { useIntl } from "../../hooks"
const MaxTempCount = 15 //TODO max temp count
const GapTime = 10000 //gap time
const Container = props => {
  const query = getQuery()
  const url = query.url
  const intl = useIntl()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [importStatus, setImportStatus] = useState("")
  const [formValue, setFormValue] = useState({
    url: "",
  })
  useEffect(() => {
    const init = () => {
      if (url) {
        setFormValue({
          url: url,
        })
      }
    }
    init()
  }, [url])
  const handleNext = async () => {
    try {
      setSubmitLoading(true)
      const { data } = await fetchApi(`/template?url=${formValue.url}`)
      setSubmitLoading(false)

      if (data.isReady) {
        // yes
        navigate(`/templates/${data.templateId}/generate`)
      } else {
        await handleCreateTemplate({ templateId: data.templateId })
      }

      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)

      // if not found
      if (error.status === 404) {
        setImportStatus("import template start...")
        try {
          await handleCreateTemplate({ url: formValue.url })
        } catch (error) {
          toast.error(error.message)
          reportError(error)
          setSubmitLoading(false)
        }
      } else {
        toast.error(error.message)
        reportError(error)
        setSubmitLoading(false)
      }
    }
  }
  const handleCreateTemplate = ({ url, templateId, resolve, reject }) => {
    let templateStateLeftCount = MaxTempCount
    let isFirstRequest = true
    const handleCreateTemplateFunc = async ({
      url,
      templateId,
      resolve,
      reject,
    }) => {
      setSubmitLoading(true)
      try {
        if (url) {
          const gitInfo = getGitInfo(url)
          const { data } = await fetchApi("/templates", {
            body: {
              name: gitInfo.name,
              url: url,
            },
          })
          templateId = data.templateId
        }
        if (templateId) {
          // get template state
          const { data: templateInfo } = await fetchApi(
            `/templates/${templateId}`
          )
          if (templateInfo.isReady) {
            // ok
            setSubmitLoading(false)
            navigate(`/templates/${templateId}/generate`)
            return resolve()
          } else {
            if (isFirstRequest) {
              isFirstRequest = false
              await fetchApi(`/templates/${templateId}/upgrade`, {
                method: "POST",
              })
            }
            // continue
            console.log("templateStateLeftCount", templateStateLeftCount)

            if (templateStateLeftCount > 0) {
              await sleep(GapTime)
              templateStateLeftCount--
              setImportStatus(
                `First import the current template, we need to init it, wait template init..., esxpected to take ${
                  (templateStateLeftCount * GapTime) / 1000
                }s`
              )

              return handleCreateTemplateFunc({ templateId, resolve, reject })
            } else {
              // error
              setSubmitLoading(false)
              setImportStatus(
                "Get template state timeout, please try it later."
              )
              reject(
                new Error("Get template state timeout, please try it later.")
              )
            }
          }
        }
      } catch (error) {
        reject(error)
      }
    }
    if (resolve && reject) {
      return handleCreateTemplateFunc({ url, templateId, resolve, reject })
    } else {
      return new Promise((resolve, reject) => {
        return handleCreateTemplateFunc({ url, templateId, resolve, reject })
      })
    }
  }
  if (loading) {
    return <LoadingWithText text="Loading templates..."></LoadingWithText>
  }
  if (error) {
    return <ErrorInfo>{error}</ErrorInfo>
  }
  return (
    <Layout>
      <SEO title="Home" />
      <Box pad="medium">
        <Heading>
          <FormattedMessage
            id="import_a_git_repository"
            defaultMessage="Import a Git Repository"
          ></FormattedMessage>
        </Heading>
        <Form
          value={formValue}
          onChange={nextValue => {
            setFormValue(nextValue)
          }}
          onReset={() => setFormValue({})}
          onSubmit={handleNext}
        >
          <FormField
            name="url"
            htmlfor="text-input-for-template-url"
            label={intl.formatMessage({
              id: "template_url",
              defaultMessage: "Template Url",
            })}
          >
            <TextInput name="url" id="text-input-for-template-url"></TextInput>
          </FormField>
          <Box direction="row" gap="medium">
            <Button
              disabled={submitLoading}
              icon={submitLoading ? <Spinner /> : null}
              type="submit"
              primary
              label="Next"
            />
          </Box>
        </Form>
        <Box pad="small">
          <Text>{importStatus}</Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default Container
